import "./Home.css";
import HeaderBar from "../components/HeaderBar";


function Home(){
    return <>
    <div style={{
              maxHeight: "calc(100vh - 11px)",
              overflowY: "auto",
            }}>
      <h1 >dfgdgdfgd</h1> <br/>
        
      <h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/>
      <h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/>
      <h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/>
      <h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/><h1>dfgdgdfgd</h1> <br/>
      </div>
    </>

}

export default Home;    