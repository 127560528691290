import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
const url = process.env.REACT_APP_URL;

const contactData = [
  {
    id: "",
    label: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    workPhone: "",
  },
];
const addressData = [
  {
    id: "",
    label: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
];

function CategoryList() {
  const [contact, setContact] = [contactData];
  const [address, setAddress] = [addressData];
  const [categoryId, setcategoryId] = useState();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    {
      dataField: "category_id",
      text: "Category Id",
      sort: true,
    },
    {
      dataField: "categoryCode",
      text: "Category Code",
    },
    {
      dataField: "categoryName",
      text: "Category Name",
    },
  ];

  const [postData, setPostData] = useState({
    categoryCode: "",
    categoryName: "",
  });
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setPostData({
      ...postData,
      [e.target.name]: value,
    });
  };

  const handleSubmit1 = (e) => {
    console.log(postData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      categoryCode: categoryId,
      categoryName: postData.categoryName,
    };

    axios.post(url + "add_category", userData).then((response) => {
      console.log(response.status);
    });
    setShow(true);
  };

  const addContact_click = () => {
    const data1 = {
      id: "",
      label: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      workPhone: "",
    };

    contact.push(data1);
    // setContact(contact);
  };

  useEffect(() => {
    const fetchcoursins = async () => {
      // setContact(contactData);
      try {
        const res = await axios.get(
          url + "category_select"
          //url +"courses?domain_id=" + domainID
        );
        setData(res.data);
        //setSupplierID
        // if (res.data[0].category_id == null) {
        //   setcategoryId("CG000" + 1);
        // } else {
        //   setcategoryId("CG000" + (parseInt(res.data[0].category_id, 10) + 1));
        // }
        // console.log(categoryId);
      } catch (error) {
        console.log(error);
      }
    };
    fetchcoursins();
  }, []);
  return (
    <>
      <Container fluid>
        <h5>Category List</h5>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Category List
            </li>
          </ol>
        </nav>
      </Container>

      <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto" }}>
        <Container
          fluid
          style={{ background: "white", width: "95%", padding: "15px" }}
        >
          <BootstrapTable
            data={data}
            columns={columns}
            keyField="id"
            striped
            hover
            condensed
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
          />
        </Container>
      </div>
    </>
  );
}

export default CategoryList;
