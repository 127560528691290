import React from "react";
import { FireFilled } from "@ant-design/icons";
function Logo() {
  return (
    <>
      <div className="logo">
        <div className="logo-icon">
          <FireFilled />
          Inventory
        </div>
      </div>
    </>
  );
}

export default Logo;
