import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const url = process.env.REACT_APP_URL;
const animatedComponents = makeAnimated();
const contactData = [
  {
    id: "",
    label: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    workPhone: "",
  },
];
const addressData = [
  {
    id: "",
    label: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
];

function OpeningStock() {
  const [contact, setContact] = [contactData];
  const [address, setAddress] = [addressData];
  const [categoryId, setcategoryId] = useState();
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const [itemId, setItemId] = useState();
  const [postData, setPostData] = useState({
    itemId: "",
    qty: "",
  });
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setPostData({
      ...postData,
      [e.target.name]: value,
    });
  };

  const handleItemChange = (e) => {
    if (e.length > 0) {
      setItemId(e[0].value);
    }
  };

  const handleSubmit1 = (e) => {
    console.log(postData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      itemId: itemId,
      qty: postData.qty,
    };

    axios.post(url + "upadte_qty", userData).then((response) => {
      console.log(response.status);
    });
    setShow(true);
  };

  const addContact_click = () => {
    const data1 = {
      id: "",
      label: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      workPhone: "",
    };

    contact.push(data1);
    // setContact(contact);
  };

  useEffect(() => {
    const fetchcoursins = async () => {
      // setContact(contactData);
      try {
        const res = await axios.get(
          url + "items"
          //url +"courses?domain_id=" + domainID
        );
        setItems(res.data);
        //setSupplierID
        // if (res.data[0].category_id == null) {
        //   setcategoryId("CG000" + 1);
        // } else {
        //   setcategoryId("CG000" + (parseInt(res.data[0].category_id, 10) + 1));
        // }
        // console.log(categoryId);
      } catch (error) {
        console.log(error);
      }
    };
    fetchcoursins();
  }, []);
  return (
    <>
      <Container fluid>
        <h5>Opening Stock</h5>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Opening Stock
            </li>
          </ol>
        </nav>
      </Container>

      <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto" }}>
        <Container
          fluid
          style={{ background: "white", width: "95%", padding: "15px" }}
        >
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Items
              </Form.Label>
              <Col sm="6">
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  //   defaultValue={[colourOptions[4], colourOptions[5]]}
                  isMulti
                  options={items}
                  onChange={handleItemChange}
                  name="itemId"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Qty
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="text"
                  placeholder="Enter Qty"
                  name="qty"
                  value={postData.qty}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Row>
              <Col sm={2}>
                <Button variant="outline-primary" onClick={handleSubmit}>
                  Add Contact
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>
          <Modal.Body>Opening Stock saved successfully</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="primary">Understood</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default OpeningStock;
