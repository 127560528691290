//https://stackoverflow.com/questions/59674690/react-router-with-ant-design-sider-how-to-populate-content-section-with-compo
import { Menu } from "antd";
import {
  HomeOutlined,
  AppstoreAddOutlined,
  AreaChartOutlined,
  PayCircleOutlined,
  SettingOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
function MenuList({ darkTheme }) {
  return (
    <>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <Menu
          theme={darkTheme ? "dark" : "light"}
          mode="inline"
          className="menu-bar"
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            Home
          </Menu.Item>
          {/* <Menu.Item key="activity" icon={<AppstoreAddOutlined />}>
            Activity
          </Menu.Item>
          <Menu.Item key="home1" icon={<AppstoreAddOutlined />}> */}
          {/* <a href="/home">Home123</a> */}
          {/* <Link to="/home">Blogs</Link>
          </Menu.Item> */}

          <Menu.SubMenu
            key="inventory"
            icon={<BarsOutlined />}
            title="Inventory"
          >
            <Menu.Item key="opening_stock">
              <Link to="/opening_stock" style={{ textDecoration: "none" }}>
                Opening Stock
              </Link>
            </Menu.Item>
            <Menu.Item key="stock_inward">
              <Link to="/stock_inward" style={{ textDecoration: "none" }}>
                Stock Inward
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="purchases"
            icon={<BarsOutlined />}
            title="Purchases"
          >
            <Menu.Item key="task-3">
              <Link to="/vendor" style={{ textDecoration: "none" }}>
                Vendor
              </Link>
            </Menu.Item>
            <Menu.Item key="task-4">
              <Link to="/purchase_order" style={{ textDecoration: "none" }}>
                Purchases Orders
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="Sales" icon={<BarsOutlined />} title="Sales">
            <Menu.Item key="sales_enquiry">
              <Link to="/sales_enquiry" style={{ textDecoration: "none" }}>
                Enquiry
              </Link>
            </Menu.Item>
            <Menu.Item key="order_form">
              <Link to="/order_form" style={{ textDecoration: "none" }}>
                Order Form
              </Link>
            </Menu.Item>
            <Menu.Item key="invoices">
              <Link to="/invoices" style={{ textDecoration: "none" }}>
                Invoices
              </Link>
            </Menu.Item>
            <Menu.Item key="sales_orders">
              <Link to="/sales_order" style={{ textDecoration: "none" }}>
                Orders
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="master" icon={<BarsOutlined />} title="Master">
            <Menu.Item key="department">
              <Link to="/department" style={{ textDecoration: "none" }}>
                Department
              </Link>
            </Menu.Item>

            <Menu.Item key="employee">
              <Link to="/employee" style={{ textDecoration: "none" }}>
                Employee
              </Link>
            </Menu.Item>

            <Menu.Item key="customer">
              <Link to="/customer_list" style={{ textDecoration: "none" }}>
                Customer
              </Link>
            </Menu.Item>

            <Menu.Item key="supplier">
              <Link to="/supplier" style={{ textDecoration: "none" }}>
                Supplier
              </Link>
            </Menu.Item>

            <Menu.Item key="category">
              <Link to="/category" style={{ textDecoration: "none" }}>
                Category
              </Link>
            </Menu.Item>

            <Menu.Item key="item">
              <Link to="/item" style={{ textDecoration: "none" }}>
                Item
              </Link>
            </Menu.Item>
            <Menu.Item key="tailor">
              <Link to="/tailor" style={{ textDecoration: "none" }}>
                Tailor
              </Link>
            </Menu.Item>
            <Menu.Item key="wharehouse">
              <Link to="/wharehouse" style={{ textDecoration: "none" }}>
                Wharehouse
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="payment" icon={<PayCircleOutlined />}>
            Payment
          </Menu.Item>
          <Menu.Item key="setting" icon={<SettingOutlined />}>
            Setting
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
}

export default MenuList;
