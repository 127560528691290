// export interface ColourOption {
//     readonly value: string;
//     readonly label: string;
//     readonly color: string;
//     readonly isFixed?: boolean;
//     readonly isDisabled?: boolean;
//   }
//https://codesandbox.io/p/sandbox/codesandboxer-example-mrdfx2?file=%2Fexample.tsx%3A7%2C1-19%2C2&module=%2Fexample.tsx
export const colourOptions = [
  {
    value: "MEASUREMENT",
    label: "MEASUREMENT",
    color: "#00B8D9",
  },
  {
    value: "STITCHING",
    label: "STITCHING SERVICES",
    color: "#0052CC",
  },
  { value: "INSTALLATION", label: "INSTALLATION", color: "#5243AA" },
  { value: "REPAIRS", label: "REPAIRS", color: "#FF5630" },
  { value: "CLEANING", label: "CLEANING & WASHING", color: "#FF8B00" },
  { value: "RELOCATION", label: "RELOCATION", color: "#FFC400" },
];
