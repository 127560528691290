import "./SalesOrders.css";

function SalesOrders(){
    return <>

    
        <div className="abc">
            Test1
           
        </div>
    </>

}

export default SalesOrders;    