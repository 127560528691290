import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from 'react-bootstrap/Table';

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const contactData = [
  { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''}
]
const addressData = [
  { id: '',
  label: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: ''}
]


function Vendor(){
const [contact, setContact] = ([contactData]);
const [address, setAddress] = ([addressData]);
const addContact_click = () => {

  const data1 = { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''}
  
  contact.push(data1);
  // setContact(contact);
}

useEffect(() => {
  
  const fetchcoursins = async () => {
    //setContact(contactData);
    // try {
    //   const res = await axios.get(
    //     url +"courses?domain_id=" + domainID
    //   );
    //   setCourses(res.data);
    //   console.log(res.data);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  fetchcoursins();
 
}, []);
    return <>
    <Container fluid>
        <h5>Vendor Master</h5>
    <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Library</li>
  </ol>
</nav>
    </Container>

    <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto", }} >
     <Container fluid style={{ background:'white', width:'95%', padding:'15px'}} >
     <Form>
     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Vendor Code
        </Form.Label>
        <Col sm="3">
        <Form.Control
        type="text"
        placeholder="S0001"
        aria-label="Disabled input example"
        readOnly
      />
        </Col>
        </Form.Group>
     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Company Name 
        </Form.Label>
        <Col sm="6">
          <Form.Control type="text" placeholder="Company Name" />
        </Col>
        </Form.Group>

        <Row className="mb-3">
        <Form.Group as={Col} controlId="gst_no">
          <Form.Label>GST Number </Form.Label>
          <Form.Control />
        </Form.Group>

        <Form.Group as={Col} controlId="gst_treatment">
          <Form.Label>GST Treatment </Form.Label>
          <Form.Control />
        </Form.Group>

        <Form.Group as={Col} controlId="pan_no">
          <Form.Label>PAN Number</Form.Label>
          <Form.Control />
        </Form.Group>
      </Row>
      
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Proprietor Name 
        </Form.Label>
        <Col sm="6">
          <Form.Control type="text" placeholder="Proprietor Name" />
        </Col>
        <Col sm="4">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></InputGroup.Text>
        <Form.Control
          placeholder="Proprietor Contact"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />  
      </InputGroup>
        </Col>
        </Form.Group>
        <Tabs
                defaultActiveKey="profile"
                id="fill-tab-example"
                className="mb-4"
                fill
              >
                <Tab eventKey="address" title="Address" className="mb-3">
                <Table striped bordered hover>
<thead>
        <tr>
          <th>#</th>
          <th>label</th>          
          <th>Address</th>
          <th>Address2</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>          
        </tr>
      </thead>
      <tbody>
       
{address.map((addr, index) => (
   <tr>
      <td>       

       {index + 1}
       
        </td> 
        
          {(addr.label != '') && (
            <td>
          {addr.label}
          </td>
          )}
        
        {(addr.label === '') && (
            <td>
          <Form.Control type="test" placeholder="label" />
          </td>
          )}
    
    {(addr.address != '') && (
            <td>
          {addr.address}
          </td>
          )}
        
        {(addr.address === '') && (
            <td>
          <Form.Control type="test" placeholder="Address" />
          </td>
          )}

{(addr.address2 != '') && (
            <td>
          {addr.address2}
          </td>
          )}
        
        {(addr.address2 === '') && (
            <td>
          <Form.Control type="test" placeholder="Address 2" />
          </td>
          )}

{(addr.city != '') && (
            <td>
          {addr.city}
          </td>
          )}
        
        {(addr.city === '') && (
            <td>
          <Form.Control type="test" placeholder="City" />
          </td>
          )}

{(addr.state != '') && (
            <td>
          {addr.city}
          </td>
          )}
        
        {(addr.state === '') && (
            <td>
          <Form.Control type="test" placeholder="State" />
          </td>
          )}
    {(addr.zip != '') && (
            <td>
          {addr.zip}
          </td>
          )}
        
        {(addr.zip === '') && (
            <td>
          <Form.Control type="test" placeholder="Zip" />
          </td>
          )}

</tr>

 ))}
 

 </tbody>
</Table>
<Button variant="outline-primary"  onClick={addContact_click} >Add Contact</Button>

   </Tab>
   <Tab eventKey="bank" title="Bank Details"  >
    
   <Form className="mb-3">
   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Bank Account Name
        </Form.Label>
        <Col sm="8">
          <Form.Control type="password" placeholder="Bank Account Name" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Bank Account Number
        </Form.Label>
        <Col sm="8">
          <Form.Control type="password" placeholder="Bank Account Number" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
          Branch Name
        </Form.Label>
        <Col sm="8">
          <Form.Control type="password" placeholder="Branch Name" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        FSC Code
        </Form.Label>
        <Col sm="8">
          <Form.Control type="password" placeholder="FSC Code" />
        </Col>
      </Form.Group>

    </Form>

</Tab>
<Tab eventKey="pcontact" title="Primary Contact" className="mb-3">

</Tab>
<Tab eventKey="ocontact" title="Other Contact" className="mb-3">
<Table striped bordered hover>
<thead>
        <tr>
          <th>#</th>
          <th>label</th>          
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Mobile No</th>
          <th> Work Phone</th>          
        </tr>
      </thead>
      <tbody>
       
{contact.map((cont, index) => (
   <tr>
      <td>       

       {index + 1}
       
        </td> 
        
          {(cont.label != '') && (
            <td>
          {cont.label}
          </td>
          )}
        
        {(cont.label === '') && (
            <td>
          <Form.Control type="test" placeholder="label" />
          </td>
          )}
    
    {(cont.firstName != '') && (
            <td>
          {cont.firstName}
          </td>
          )}
        
        {(cont.firstName === '') && (
            <td>
          <Form.Control type="test" placeholder="First Name" />
          </td>
          )}

{(cont.lastName != '') && (
            <td>
          {cont.lastName}
          </td>
          )}
        
        {(cont.lastName === '') && (
            <td>
          <Form.Control type="test" placeholder="Last Name" />
          </td>
          )}

{(cont.email != '') && (
            <td>
          {cont.email}
          </td>
          )}
        
        {(cont.email === '') && (
            <td>
          <Form.Control type="test" placeholder="Email" />
          </td>
          )}

{(cont.workPhone != '') && (
            <td>
          {cont.workPhone}
          </td>
          )}
        
        {(cont.workPhone === '') && (
            <td>
          <Form.Control type="test" placeholder="Work Number" />
          </td>
          )}
    {(cont.mobileNo != '') && (
            <td>
          {cont.mobileNo}
          </td>
          )}
        
        {(cont.mobileNo === '') && (
            <td>
          <Form.Control type="test" placeholder="Mobile Number" />
          </td>
          )}

</tr>

 ))}
 

 </tbody>
</Table>
<Button variant="outline-primary"  onClick={addContact_click} >Add Contact</Button>
</Tab>
<Tab eventKey="odetail" title="Other Details" className="mb-3">
<Form>
   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Payment terms 
        </Form.Label>
        <Col sm="8">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">No. Of days</InputGroup.Text>
        <Form.Control
          placeholder="Payment"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Discount on bill
        </Form.Label>
        <Col sm="8">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Percentage </InputGroup.Text>
        <Form.Control
          placeholder="Discount"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />  
      </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload Documents</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
      </Form>
</Tab>
</Tabs>
     </Form>


     <Row>
            <Col sm={2}>
            <Button variant="outline-primary"  onClick={addContact_click} >Add Contact</Button>
            </Col>
        </Row>  
     </Container>
</div>
    </>

}

export default Vendor;