import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

import BootstrapTable from "react-bootstrap-table-next";


import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
const url = process.env.REACT_APP_URL;

const contactData = [
  {
    id: "",
    label: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    workPhone: "",
  },
];
const addressData = [
  {
    id: "",
    label: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
];






function CustomerListing() {
  const [contact, setContact] = [contactData];
  const [address, setAddress] = [addressData];
  const [categoryId, setcategoryId] = useState();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showCustAddr, setShowCustAddr] = useState(false);
  const [showCustContact, setShowCustContact] = useState(false);
  const [showCustBank, setShowCustBank] = useState(false);
  const [showOtherDt, setShowOtherDt] = useState(false);

  const [custAddrData, setCustAddrData] = useState([]);
  const [custContactData, setCustContactData] = useState([]);

  const [custBankData, setCustBankData] = useState({});
  const [custOtherDtData, setCustOtherDtData] = useState({});

  const navigate = useNavigate();

  const contactformater = (data,row) =>{
    
    const handleCustContact = (row,data) => {
      console.log(row)
      console.log(data)
      axios.get( url +"customer_contact_select?cust_id=" + row.customer_id).then((response) => {
        console.log(response.data);  
        setCustContactData(response.data);
        setShowCustContact(true)
        setShowCustAddr(false)
        setShowCustBank(false)
        setShowOtherDt(false)
      });
      
    }
  
    return <>
  
    <Button variant="link" onClick={() => handleCustContact(row,'contact')} >Show</Button>
   
    </>
  }

  const addressformater = (data,row) =>{
  
    const handleAddressGet = (data,row) => {
      console.log(row)
      console.log(data)
      axios.get( url +"customer_addr_select?cust_id=" + data.customer_id).then((response) => {
        console.log(response.data);  
        setCustAddrData(response.data);
        setShowCustContact(false)
        setShowCustAddr(true)
        setShowCustBank(false)
        setShowOtherDt(false)
      });
    }
  
    return <>
  
    <Button variant="link" onClick={() => handleAddressGet(row,'address')} >Show</Button>
  
   
    </>
  }


  const bankDetailsformater = (data,row) =>{
  
    const handleSaveBankDetails = (data,row) => {
      console.log(row)
      console.log(data)
      setCustBankData(data)
      setShowCustContact(false)
        setShowCustAddr(false)
        setShowCustBank(true)
        setShowOtherDt(false)

      // axios.get( url +"customer_addr_select?cust_id=" + data.customer_id).then((response) => {
      //   console.log(response.data);  
      //   setCustAddrData(response.data);
      //   setShowCustContact(false)
      //   setShowCustAddr(true)
      // });
    }
  
    return <>
  
    <Button variant="link" onClick={() => handleSaveBankDetails(row,'bank')} >Show</Button>
  
   
    </>
  }

  
  const otherDtformater = (data,row) =>{
  
    const handleSaveOtherDetails = (data,row) => {
      console.log(row)
      console.log(data)
      setCustOtherDtData(data)
      setShowCustContact(false)
        setShowCustAddr(false)
        setShowCustBank(false)
        setShowOtherDt(true)

      // axios.get( url +"customer_addr_select?cust_id=" + data.customer_id).then((response) => {
      //   console.log(response.data);  
      //   setCustAddrData(response.data);
      //   setShowCustContact(false)
      //   setShowCustAddr(true)
      // });
    }
  
    return <>
  
    <Button variant="link" onClick={() => handleSaveOtherDetails(row,'bank')} >Show</Button>
  
   
    </>
  }


  const customer_contact = [
    {
      dataField: "cust_contact_id",
      text: "Contact ID",
      sort: true,
      editable: false,
    },
    {
      dataField: "label",
      text: "Label",
    },
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobileNo",
      text: "Mobile No",
    },
    {
      dataField: "workPhone",
      text: "Work Phone",
    },

  ]
  const customer_addr = [
    {
      dataField: "cust_addr_id",
      text: "Address_id",
      sort: true,
      editable: false,
    },
    {
      dataField: "label",
      text: "Label",
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "address2",
      text: "Address2",
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "state",
      text: "State",
    },
    {
      dataField: "zip",
      text: "Zip",
    },

  ]

  const columns = [
    {
      dataField: "customer_id",
      text: "Customer Id",
      sort: true,
      editable: false,
    },
    {
      dataField: "clientName",
      text: "Client Name",
    },
    {
      dataField: "clientCategory",
      text: "Client Category",
    },
    {
      dataField: "DOB",
      text: "DOB",   
      
    },
    {
      dataField: "adress",
      text: "Address",
      formatter: addressformater,
      editable: false,
    },
    {
      dataField: "contact",
      text: "Contact",
      formatter: contactformater,
      editable: false,
    },
    {
      dataField: "bank",
      text: "Bank Details",
      formatter: bankDetailsformater,
      editable: false,
    },
    {
      dataField: "other",
      text: "Orther Details",
      formatter: otherDtformater,
      editable: false,
    },
  ];
  

  const [postData, setPostData] = useState({
    categoryCode: "",
    categoryName: "",
  });
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setPostData({
      ...postData,
      [e.target.name]: value,
    });
  };

//siva

  const handleRedirect = (e) => {
    navigate("/customer");
  };

  const handleSubmit1 = (e) => {
    console.log(postData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      categoryCode: categoryId,
      categoryName: postData.categoryName,
    };

    axios.post(url + "add_category", userData).then((response) => {
      console.log(response.status);
    });
    setShow(true);
  };

  const addContact_click = () => {
    const data1 = {
      id: "",
      label: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      workPhone: "",
    };

    contact.push(data1);
    // setContact(contact);
  };

  useEffect(() => {
    const fetchcoursins = async () => {
      // setContact(contactData);
      try {
        const res = await axios.get(
          url + "customer_select"
          //url +"courses?domain_id=" + domainID
        );
        setData(res.data);
        //setSupplierID
        // if (res.data[0].category_id == null) {
        //   setcategoryId("CG000" + 1);
        // } else {
        //   setcategoryId("CG000" + (parseInt(res.data[0].category_id, 10) + 1));
        // }
        // console.log(categoryId);
      } catch (error) {
        console.log(error);
      }
    };
    fetchcoursins();
  }, []);
  return (
    <>
      <Container fluid>
        <h5> Customer List</h5>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
            Customer List
            </li>
          </ol>
        </nav>
      </Container>

      <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto" }}>
        <Container
          fluid
          style={{ background: "white", width: "95%", padding: "15px" }}
        >
           <Row className="mb-2" style={{marginRight:'0px'}}>
<Col sm='11'>
</Col >

            <Col sm={1}  >
            <Button variant="outline-primary" onClick={handleRedirect} >Add</Button>
            </Col>
        </Row>
        <Row className="mb-2" style={{marginRight:'0px'}}>
        <Col sm='12'> 
          <BootstrapTable
            data={data}
            columns={columns}
            keyField="id"
            striped
            hover
            condensed
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
          />
          </Col>
          </Row>
          {showCustAddr && (  
          <Row className="mb-2" style={{marginRight:'0px'}}>
        <Col sm='12'> 
          <BootstrapTable
            data={custAddrData}
            columns={customer_addr}
            keyField="id"
            striped
            hover
            condensed
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
          />
          </Col>
          </Row>
          )}

{showCustContact && (  
          <Row className="mb-2" style={{marginRight:'0px'}}>
        <Col sm='12'> 
          <BootstrapTable
            data={custContactData}
            columns={customer_contact}
            keyField="id"
            striped
            hover
            condensed
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
          />
          </Col>
          </Row>
          )}


{showCustBank && (  
          <Form className="mb-3">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
               Bank Account Name
               </Form.Label>
               <Col sm="8">
                 <Form.Control type="text" placeholder="Bank Account Name" 
                 name="bankAccName"
                 value={custBankData.bankAccName}
                 onChange={handleChange}
                 />
               </Col>
             </Form.Group>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
               Bank Account Number
               </Form.Label>
               <Col sm="8">
                 <Form.Control type="text" placeholder="Bank Account Number" 
                  name="bankAccNumber"
                  value={custBankData.bankAccNumber}
                  onChange={handleChange}
                 />
               </Col>
             </Form.Group>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
                 Branch Name
               </Form.Label>
               <Col sm="8">
                 <Form.Control type="text" placeholder="Branch Name" 
                 name="branchName"
                 value={custBankData.branchName}
                 onChange={handleChange}          
                 />
               </Col>
             </Form.Group>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
               FSC Code
               </Form.Label>
               <Col sm="8">
                 <Form.Control type="text" placeholder="FSC Code" 
                  name="fscCode"
                  value={custBankData.fscCode}
                  onChange={handleChange}  
                 />
               </Col>
             </Form.Group>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
             <Col sm={3}>
            <Button variant="outline-primary"   onClick={handleSubmit} >Save</Button>
            </Col>
              </Form.Group>
           </Form>
          )}


{showOtherDt && (  
          <Form>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
               Payment terms 
               </Form.Label>
               <Col sm="8">
               <InputGroup className="mb-3">
               <InputGroup.Text id="basic-addon1">No. Of days</InputGroup.Text>
               <Form.Control
                 placeholder="Payment"
                 aria-label="Username"
                 aria-describedby="basic-addon1"
                 name="pay_terms"
                 value={custOtherDtData.pay_terms}
                 onChange={handleChange}
               />
             </InputGroup>
               </Col>
             </Form.Group>
       
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
               <Form.Label column sm="4">
               Discount on bill
               </Form.Label>
               <Col sm="8">
               <InputGroup className="mb-3">
               <InputGroup.Text id="basic-addon1">Percentage </InputGroup.Text>
               <Form.Control
                 placeholder="Discount"
                 aria-label="Username"
                 aria-describedby="basic-addon1"
                 name="discount"
                 value={custOtherDtData.discount}
                 onChange={handleChange}
               />  
             </InputGroup>
               </Col>
             </Form.Group>
       
             <Form.Group controlId="formFile" className="mb-3">
               <Form.Label>Upload Documents</Form.Label>
               <Form.Control type="file" />
             </Form.Group>
             </Form>
          )}
        </Container>
      </div>  
    </>
  );
}

export default CustomerListing;
