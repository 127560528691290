import "./OrderForm.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import CustomMenu from "../components/CustomMenu";
import ServiceDropdown from '../components/ServiceDropdown'
import Table from 'react-bootstrap/Table';
import React from 'react'
import Select from 'react-select'

const options1 = [
  { value: 'MEASUREMENT', label: 'MEASUREMENT' },
  { value: 'STITCHING_SERVICES', label: 'STITCHING SERVICES' },
  { value: 'INSTALLATION', label: 'INSTALLATION' },
  { value: 'REPAIRS', label: 'REPAIRS' },
  { value: 'CLEANING_WASHING', label: 'CLEANING & WASHING' },
  { value: 'RELOCATION', label: 'RELOCATION' }
]


function OrderForm() {
  const [date, setDate] = useState(new Date());
  return (
    
    <>



    <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto", marginLeft:'15px',}} >
    
    <Form style={{ paddingLeft: "10px" , width:'100%' }} >
        <Row className="mb-12">
          <Form.Label>
            <h4 style={{ color: "blueviolet" }}> Order Form</h4>
          </Form.Label>
        </Row>

        <Row >
          <Col sm="2">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
             Customer Name *
            </span>
          </Col>
          <Col sm="8">
          <CustomMenu></CustomMenu>
          <Row>
            <Col sm='6'>
            <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>BILLING ADDRESS  *</Form.Label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"   style={{ border:'1px solid gray',  }}></textarea>
            </Col>
            <Col sm = '6'>
            <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>SHIPPING ADDRESS *</Form.Label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"   style={{ border:'1px solid gray',  }}></textarea>
            </Col>
        </Row>

          </Col>
         
          
        </Row>

        <Row  style={{ marginTop: '10px'}}>
          <Col sm="2">
          <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> Sales Order# *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
          
          </Col>
          <Col sm="2">
          <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> SKU ID</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
          </Col>
          <Col sm="2">
          <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> Sales Order Date * </Form.Label>
          <Form.Control
                type="date"
                name="datepic"
                placeholder="DateRange"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{color:'blue'}}
              />
            </Col>
            <Col sm="3">
          <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> Expected Shipment Date * </Form.Label>
          <Form.Control
                type="date"
                name="datepic"
                placeholder="DateRange"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{color:'blue'}}
              />
            </Col>
            </Row>
            <Row>
                <Row> <Col>  <div class="d-flex justify-content-center">
                <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem", alignItems:'center',fontWeight:'bold' }}> Oreder Details </Form.Label> 
                </div>
                </Col>  </Row>
                
                <Col sm ='12'>
                <Table striped bordered hover>
      <thead >      
        <tr class="d-flex">
          <th class="col-1">#</th>
          <th class="col-3">Product Desc</th>
          <th class="col-1">Color</th>
          <th class="col-1" >Size</th>
          <th class="col-1" >Price</th>          
          <th class="col-1" >Qty</th>
          <th class="col-1">Discount</th>
          <th class="col-3">Service </th>        
        </tr>
      </thead>
      <tbody>
        <tr class="d-flex">
          <td class="col-1">1</td>
          <td class="col-3">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
     
         <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-3">
            <ServiceDropdown/>
         </td>
        </tr> 

        <tr class="d-flex">
          <td class="col-1">2</td>
          <td class="col-3">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
     
         <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-3">
            <ServiceDropdown/>
         </td>
        </tr> 

        <tr class="d-flex">
          <td class="col-1">3</td>
          <td class="col-3">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
     
         <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-1">
            <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
      </td>
      <td class="col-3">
            <ServiceDropdown/>
         </td>
        </tr> 

      </tbody>
    </Table>
                </Col>
            </Row>
          <Row>

          <Col sm='3' id = 'develiory' >
          <Row  >
         
            <Col>
            <div class="d-flex justify-content-center">
            <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem",fontWeight:'bold' }}> DELIVERY</Form.Label>
            </div>
            </Col>
          </Row>
        <Row>
            <Col>
            <Form.Check
              type="checkbox"
              label="SHIP"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem",  }}
            />
            </Col>
            <Col>
            <Form.Check
           
              type="checkbox"
              label="PICK-UP"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem",}}
            />           </Col>
        </Row>
          <Row>
            <Col>
            <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> SHIPPING DETAILS</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
            </Col>
            
          </Row>
          <Row>
          <Col>
            <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> SHIPPING DATE</Form.Label>
      <Form.Control
        type="date"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
            </Col>
          </Row>
          </Col>
          <Col id ='payment' sm='4' >
          <Row  >
           
            <Col >
            <div class="d-flex justify-content-center">
            <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem", fontWeight:'bold ' }} > PAYMENT METHOD</Form.Label>
           </div>
            </Col>
          
          </Row>
          <Row>
            <Col sm='3'>
            <Form.Check
              type="checkbox"
              label="CASH"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
            </Col>
            <Col sm='3'>
            <Form.Check
              type="checkbox"
              label="CARD"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
            </Col>
            <Col sm='3'>
            <Form.Check
              type="checkbox"
              label="GPAY"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
            </Col>
            <Col sm='3'>
            <Form.Check
              type="checkbox"
              label="OTHERS"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
            </Col>

          </Row>
<Row>
    <Col> 
    <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}> DETAILS</Form.Label>
    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"   style={{ border:'1px solid gray',  }}></textarea>
    </Col>
    </Row>

          </Col>
          <Col id ='total' sm='5'>
          <Row  >
            <Col sm='4'>           
            </Col>
            <Col>
            <div class="d-flex justify-content-center">
            <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" , fontWeight:'bold'}}> ORDER TOTAL </Form.Label>
            </div>
            </Col>  
          </Row>

          <Row >
          <Col >
            <span style={{ color: "blueviolet", fontSize: "1.0rem", padding:'10px' }} class="float-end" >
            SUBTOTAL
            </span >
          </Col>
          <Col >
          <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',}}
      />
          </Col>
          </Row>

          <Row >
          <Col >
            <span style={{ color: "blueviolet", fontSize: "1.0rem", padding:'10px'  }} class="float-end">
            TAX
            </span>
          </Col>
          <Col >
          <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray', marginTop:'5px'  }}
      />
          </Col>
          </Row>

          <Row >
          <Col >
            <span style={{ color: "blueviolet", fontSize: "1.0rem",  padding:'10px'  }} class="float-end">
            SHIPPING
            </span>
          </Col>
          <Col >
          <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',marginTop:'5px' }}
      />
          </Col>
          </Row>

          <Row >
          <Col>
            <div>
            <span style={{ color: "blueviolet", fontSize: "1.0rem",  padding:'10px'  }} class="float-end">
            TOTAL
            </span>
            </div>
          </Col>
          <Col >
          <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'white' , border:'1px solid gray',marginTop:'5px' }}
      />
          </Col>
          </Row>


          </Col>

          </Row>
          
        </Form>

   

    </div>
    </>
  )
}
export default OrderForm;
