import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function HeaderBar() {
  return (
    <>
      <div>
        <Navbar className="bg-body-tertiary">
          <Container style={{ paddingTop: "5px", marginTop: "-6px" }}>
            <Navbar.Brand href="#home">
              <span style={{ color: "blue", padding: "0px", margin: "0px" }}>
                <h2 style={{ paddingLeft: "0px" }}>Fabric Sense</h2>
              </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Signed in as: <a href="#login">Mark Otto</a>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default HeaderBar;
