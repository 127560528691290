import React from "react";
import { Button } from "antd";
import { HiOutlineMoon, HiOutlineSun } from "react-icons/hi";

function ToggleThemeButton({ darkTheme, toggleTheme }) {
  return (
    <>
      <div className="toggle-theme-btn">
        <Button onClick={toggleTheme}>
          {darkTheme ? <HiOutlineSun /> : <HiOutlineMoon />}
        </Button>
      </div>
    </>
  );
}

export default ToggleThemeButton;
