import "./SalesEnquiry.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

function SalesEnquiry() {
  const [date, setDate] = useState(new Date());
  return (
    <>
    <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto", }} >
      <Form style={{ paddingLeft: "25px" , width:'100%' }} >
        <Row className="mb-12">
          <Form.Label>
            <h4 style={{ color: "blueviolet" }}> New Enquiry</h4>
          </Form.Label>
        </Row>
        <Row >
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
              What is your requirements?
            </span>
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Renovation"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="New House"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          
        </Row>

        <Row style={{ paddingTop:'10px'}}>
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
              How Big is your space?
            </span>
          </Col>
          <Col sm="1">
            <Form.Check
              type="radio"
              label="1BHK"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="1">
            <Form.Check
              type="radio"
              label="2BHK"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="1">
            <Form.Check
              type="radio"
              label="3BHK"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          
          <Col sm="1">
            <Form.Check
              type="radio"
              label="Duplix"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="1">
            <Form.Check
              type="radio"
              label="Others"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>        
          
        </Row>
        <Row style={{ paddingTop:'10px'}}>
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
            Which room would you like to style?
            </span>
          </Col>
          <Col sm='8'>
          <Row >
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Living Room"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Bed Room "
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Kids Room"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>          
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Guest Room"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Entire House"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col> 
          </Row>       
          </Col>
        </Row>

        <Row style={{ paddingTop:'10px'}}>
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
            What product you are looking for?
            </span>
          </Col>
          <Col sm='8'>
            <Row>
              <Col sm='6'>
              <Form.Select aria-label="Default select example" style={{ color: 'blue'}}>
      <option>Select Product</option>
      <option value="1">Curtain</option>
      <option value="2">Bedding</option>
      <option value="3">WallPapers</option>
      <option value="4">Rugs</option>
      <option value="5">Accessories</option>
    </Form.Select>
              </Col>
            </Row>
          {/* <Row >
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Curtain"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Bed"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="WallPapers"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>          
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Rugs"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="2">
            <Form.Check
              type="radio"
              label="Accessories"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col> 
          </Row>        */}
          </Col>
        </Row>

        <Row style={{ paddingTop:'10px'}}>
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
            What is your budget?
            </span>
          </Col>
          <Col sm='8'>
          <Row >
          <Col sm="3">
          <Form.Control type="text"   />
          </Col>  
          </Row>       
          </Col>
        </Row>

        <Row style={{ paddingTop:'10px'}} >
          <Col sm="4">
            <span style={{ color: "blueviolet", fontSize: "1.0rem" }}>
            What Service Requiblue?
            </span>
          </Col>
          <Col sm='8'>
          <Row >
          <Col sm="3">
            <Form.Check
              type="checkbox"
              label="MEASUREMENT"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="4">
            <Form.Check
              type="checkbox"
              label="STITCHING SERVICES"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="3">
            <Form.Check
              type="checkbox"
              label="INSTALLATION"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          
          </Row>
<Row>
<Col sm="3">
            <Form.Check
              type="checkbox"
              label="REPAIRS"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="4">
            <Form.Check
              type="checkbox"
              label="CLEANING & WASHING"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>
          <Col sm="3">
            <Form.Check
              type="checkbox"
              label="RELOCATION"
              name="group2"
              id="radio2"
              style={{ color: "blue", fontSize: "1.0rem" }}
            />
          </Col>

</Row>


          </Col>
        </Row>
        
      </Form>

      <Form style={{ paddingLeft: "25px" }} >
        <Row>

          <Form.Label>
            <h5 style={{ color: "blueviolet" }}> Contact Details</h5>
          </Form.Label>
        </Row>
        <Row>
        <Col sm="3">
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Name *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        style={{backgroundColor: 'gainsboro'}}
      />
      
          </Col>
          <Col sm="3">
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Mobile Number *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'gainsboro'}}
      />
      
          </Col>
          <Col sm="3">
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Emai *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'gainsboro'}}
      />
      
          </Col>
          </Row>

          <Row>
        <Col sm="3">
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Right date to Contact *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'gainsboro'}}
      />
      
          </Col>

          <Col sm="3">
        <Form.Label htmlFor="inputPassword5" style={{ color: "blue", fontSize: "1.0rem" }}>Right Time to Contact *</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'gainsboro'}}
      />
      
          </Col>
          <Col sm="3">
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Person to send Date Time *</Form.Label>
      {/* <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock" style={{backgroundColor: 'gainsboro'}}
      /> */}
       <Form.Control
                type="date"
                name="datepic"
                placeholder="DateRange"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{color:'blue'}}
              />
          </Col>
          </Row>
       <Row>
        <Col sm='5'>
        <Form.Label htmlFor="inputPassword5"  style={{ color: "blue", fontSize: "1.0rem" }}>Message *</Form.Label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"   style={{ border:'1px solid gray', backgroundColor: 'gainsboro' }}></textarea>
        </Col>
        <Col sm='3'>

        <Button variant="primary" style={{ marginTop:'30%' }}>Submit Enquiry</Button>
       
        </Col>
       </Row>
     
        </Form>

        </div>


    </>
  );
}

export default SalesEnquiry;
