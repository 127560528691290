import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const url = process.env.REACT_APP_URL;
//https://youtu.be/rgY1oPNVgwU
function Chart(){
    const [data, setData] = useState([]);
    const columns=[{
        dataField:"email",
        text: "Email",
        sort:true
        },
        {
        dataField:"id",
        text: "Product ID",
        },
        ]
    useEffect(() => {
        const fetchTest = async () => {
          try {   
            //const res1 = await axios.get("https://edu-careerpath-api.azurewebsites.net/api/domains?code=bEIbFtvQ43rWjRr2BugRhcX4qwWijnyS8h9QX-tuC3j8AzFuNqqYbg=="); 
            console.log(url);
            const res = await axios.get("https://jsonplaceholder.typicode.com/comments"); 
            console.log(res.data);  
            setData(res.data)
            // setDomains(res.data);
           // console.log(res1);
          } catch (error) {
            console.log(error);
          }
        };
        fetchTest();
      }, []);
    return <>
     <Container fluid>
        <div>
        <BootstrapTable data={data} columns={columns} keyField="id" striped hover condensed pagination={ paginationFactory() } cellEdit={ cellEditFactory({ mode: 'click', blurToSave:true })}/>
        </div>
        </Container>
    </>

}

export default Chart;