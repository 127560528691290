import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from 'react-bootstrap/Table';

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ServiceDropdown from "../components/ServiceDropdown";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Modal from 'react-bootstrap/Modal';
//import { colourOptions } from "./ServiceData";
const url = process.env.REACT_APP_URL;
const animatedComponents = makeAnimated();
const contactData = [
  { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''}
]
const addressData = [
  { id: '',
  label: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: ''}
]


function Item(){
const [contact, setContact] = useState([contactData]);
const [address, setAddress] = useState([addressData]);
const [category, setCategory] = useState([]);
const [supplier, setSupplier] = useState([]);
const [category_ID, setCategory_ID] = useState([]);
const [supplier_ID, setSupplier_id] = useState([]);
const [itemId, setItemId] = useState();
const [show, setShow] = useState(false);
const [postData, setPostData] = useState({	
  itemCode: "",
  itemName: "",
  categoryId: "",
  productType: "",
  supplierId: "",
  size: "",
  unitOfMeasure: "",
  hsnCode: "",
  basicPrice: "",
  purchasePrice: "",
  sellingPrice: "",
  reOrderLevel: "",
  mbq: "",
  tags: "",
  discountRate: "",
  gstRate: "",
  discontinued: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setPostData({
      ...postData,
      [e.target.name]: value
    });
  };
  const handleCategoryChange = (e) => {
    if ( e.length > 0){
    setCategory_ID(e[0].value)
    }
  }
  const handleClose = () => setShow(false);
  const handleSupplierChange = (e) => {
    if ( e.length > 0){
    setSupplier_id(e[0].value)
    }
  }

  

  const handleSubmit1 = (e) => {
    console.log(postData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
    itemCode: itemId,
  itemName: postData.itemName,
  categoryId: category_ID,
  productType: postData.productType,
  supplierId: supplier_ID,
  size: postData.size,
  unitOfMeasure: postData.unitOfMeasure,
  hsnCode: postData.hsnCode,
  basicPrice: postData.basicPrice,
  purchasePrice: postData.purchasePrice,
  sellingPrice: postData.sellingPrice,
  reOrderLevel: postData.reOrderLevel,
  mbq: postData.mbq,
  tags: postData.tags,
  discountRate: postData.discountRate,
  gstRate: postData.gstRate,
  discontinued: 'no'
    
    };
  
    axios.post( url +"add_item", userData).then((response) => {
      console.log(response.status);
    });
    setShow(true);
  }
  

const addContact_click = () => {

  const data1 = { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''}
  
  contact.push(data1);
  // setContact(contact);
}

useEffect(() => {
  
  const fetchcoursins = async () => {
    // setContact(contactData);
    try {
      const res = await axios.get(
        url +"category"
        //url +"courses?domain_id=" + domainID
      );
     setCategory(res.data);

     const res1 = await axios.get(
      url +"supplier"
      //url +"courses?domain_id=" + domainID
    );
    setSupplier(res1.data);


    const res2 = await axios.get(
      url +"item_id"
      //url +"courses?domain_id=" + domainID
    );
   // setCourses(res.data);
   //setSupplierID     
   if ( res2.data[0].item_id == null){
    setItemId('I000'+ 1)
   } else{
    setItemId('I000'+ (parseInt(res2.data[0].item_id , 10) + 1))
   }  
  
     
    } catch (error) {
      console.log(error);
    }
  };  
  fetchcoursins();
 
}, []);
    return <>
    <Container fluid>
        <h5>Item Master</h5>
    <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Item</li>
  </ol>
</nav>
    </Container>

    <div style={{ maxHeight: "calc(100vh - 95px)", overflowY: "auto", }} >
     <Container fluid style={{ background:'white', width:'95%', padding:'15px'}} >
     <Form>
     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Item Code
        </Form.Label>
        <Col sm="3">
        <Form.Control
        type="text"
        placeholder= {itemId}
        aria-label="Disabled input example"
        readOnly
      />
      </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Item Name
        </Form.Label>
        <Col sm="8">
        <Form.Control
        type="text"
        placeholder="Item Name"
        aria-label="Disabled input example"
        name="itemName"
          value={postData.itemName}
          onChange={handleChange}
       
      />
      </Col>
        </Form.Group>

     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">

     <Form.Group as={Col} controlId="formGridState" sm={5}>
          <Form.Label>Category</Form.Label>

          <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      options={category}
      onChange={handleCategoryChange}  name="categoryId"
    />

        </Form.Group>
        <Form.Group as={Col} controlId="formGridState" sm={5}>
          <Form.Label>Product Type</Form.Label>
          <Form.Select defaultValue="Retail"  onChange={handleChange}  name="productType" >
            <option>Product1</option>
            <option>Product1</option>
            <option>Product1</option>
            <option>Product1</option>
            <option>Product1</option>
          </Form.Select>
        </Form.Group>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">

<Form.Group as={Col} controlId="formGridState" sm={5}>
     <Form.Label>Vendor</Form.Label>
     <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      options={supplier}
      onChange={handleSupplierChange}  name="supplierId"
    />

   </Form.Group>
   <Form.Group as={Col} controlId="formGridState" sm={5}>
     <Form.Label>Preferred Vendor</Form.Label>
     <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      options={supplier}
    />
   </Form.Group>
   </Form.Group>

   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Size</Form.Label>
<Form.Control   placeholder="Enter Size"
        aria-label="Disabled input example"
        name="size"
          value={postData.size}
          onChange={handleChange} />
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Unit Of Measure</Form.Label>
<Form.Control   placeholder="Enter Unit Of Measure" 
name="unitOfMeasure"
value={postData.unitOfMeasure}
onChange={handleChange}
/>
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>HSN Code</Form.Label>
<Form.Control   placeholder="Enter HSN Code" 
name="hsnCode"
value={postData.hsnCode}
onChange={handleChange}
/>
</Form.Group>


 </Form.Group>

 <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Basic Price </Form.Label>
<Form.Control placeholder="Enter Basic Price" 
name="basicPrice"
value={postData.basicPrice}
onChange={handleChange}
/>
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Purchase Price</Form.Label>
<Form.Control placeholder="Enter Purchase Price" 
name="purchasePrice"
value={postData.purchasePrice}
onChange={handleChange}
/>
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Selling Price</Form.Label>
<Form.Control placeholder="Enter Selling Price" 
name="sellingPrice"
value={postData.sellingPrice}
onChange={handleChange}
 />
</Form.Group>


 </Form.Group>

 <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">


<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>RE-Order Level</Form.Label>
<Form.Control placeholder="Enter RE-Order Level" 
name="reOrderLevel"
value={postData.reOrderLevel}
onChange={handleChange}
/>
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>MBQ</Form.Label>
<Form.Control placeholder="Enter MBQ" 
name="mbq"
value={postData.mbq}
onChange={handleChange}
 />
</Form.Group>
<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Tags</Form.Label>
<Form.Control placeholder="Enter Tags" 
name="tags"
value={postData.tags}
onChange={handleChange}
/>
</Form.Group>

 </Form.Group>


 <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
 <Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Discount Rate</Form.Label>
<Form.Control  placeholder="Enter Discount Rate" 
name="discountRate"
value={postData.discountRate}
onChange={handleChange}
 />
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>GST Rate</Form.Label>
<Form.Control placeholder="Enter GST Rate" 
name="gstRate"
value={postData.gstRate}
onChange={handleChange}
/>
</Form.Group>

<Form.Group as={Col} controlId="formGridState" sm={3}> 
<Form.Label>Discontinued</Form.Label>
<Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Active/Inactive"
        name="discontinued"
        value={postData.discontinued}
        onChange={handleChange}

      />
</Form.Group>


 </Form.Group>


        <Row className="mb-5">
            <Col sm={2}>
            <Button variant="outline-primary"  onClick={handleSubmit} >Save</Button>
            </Col>
        </Row>
      
        

     </Form>



     </Container>
</div>
<div>
<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The Item Data saved successfully
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
</div>
    </>

}

export default Item;