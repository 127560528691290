import { useState } from "react";
import { Button, Layout, theme } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Logo from "./components/Logo";
import "./App.css";
import MenuList from "./components/MenuList";
import ToggleThemeButton from "./components/ToggleThemeButton";
import Home from "./pages/Home";
import Ventor from "./pages/Vendor";
import SalesOrders from "./pages/SalesOrders";
import SalesEnquiry from "./pages/SalesEnquiry";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import HeaderBar from "./components/HeaderBar";
import OrderForm from "./pages/OrderForm";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Customer from "./pages/Customer";
import Supplier from "./pages/Supplier";
import Chart from "./pages/Chart";
import Category from "./pages/Category";
import Item from "./pages/Item";
import Tailor from "./pages/Tailor";
import OpeningStock from "./pages/OpeningStock";
import StockInward from "./pages/StockInward";
import CategoryList from "./pages/CategoryList";
import CustomerListing from "./pages/CustomerListing";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
const { Header, Sider } = Layout;

function App() {
  const [darkTheme, setDarkTheme] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Router>
        <Layout style={{ heigh: "100%" }}>
          <div
            style={{
              maxHeight: "calc(100vh - 11px)",
              overflowY: "auto",
            }}
          >
            <Sider theme={darkTheme ? "dark" : "light"} className="sidebar">
              <Logo />
              <MenuList darkTheme={darkTheme} />
            </Sider>
          </div>
          <Layout>
            <div>
              {" "}
              <HeaderBar />{" "}
            </div>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/vendor" element={<Ventor />} />
              <Route path="/sales_order" element={<SalesOrders />} />
              <Route path="/sales_enquiry" element={<SalesEnquiry />} />
              <Route path="/order_form" element={<OrderForm />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/supplier" element={<Supplier />} />
              <Route path="/chat" element={<Chart />} />
              <Route path="/category" element={<Category />} />
              <Route path="/item" element={<Item />} />
              <Route path="/tailor" element={<Tailor />} />
              <Route path="/opening_stock" element={<OpeningStock />} />
              <Route path="/stock_inward" element={<StockInward />} />
              <Route path="/category_list" element={<CategoryList />} />
              <Route path="/customer_list" element={<CustomerListing />} />
            </Routes>
          </Layout>
        </Layout>
      </Router>
    </>
  );
}

export default App;
