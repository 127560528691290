import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from 'react-bootstrap/Table';

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
  const url = process.env.REACT_APP_URL;
const contactData = [
  { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''}
]
const addressData = [
  { id: '',
  label: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: ''}
]


function Supplier(){
const [contact, setContact] = useState([contactData]);
const [address, setAddress] = useState([addressData]);
const [errAddr, setErrAddr] = useState();
const [errContact, setErrContact] = useState();

const [supplierId, setSupplierId] = useState();
const [show, setShow] = useState(false);
const [postData, setPostData] = useState({  
  companyName: "",
  gstNumber: "",
  gstTreatment: "",
  panNumber: "",
  propretorName: "",
  propretorContact: "",
  label: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  bankAccName: "",
  bankAccNumber: "",
  branchName: "",
  fscCode: "",
  contact_label: "",
  firstName: "",
  lastName: "",
  email: "",
  workPhone: "",
  mobileNo: "",
  pay_terms: "",
  discount: ""
});

const handleChange = (e) => {
  const value = e.target.value;
  setPostData({
    ...postData,
    [e.target.name]: value
  });
};

const handleSubmit1 = (e) => {
  console.log(postData);
}
const handleClose = () => setShow(false);
const handleSubmit = (e) => {
  e.preventDefault();
  const userData = {
    companyName: postData.companyName,
  gstNumber: postData.gstNumber,
  gstTreatment: postData.gstTreatment,
  panNumber: postData.panNumber,
  propretorName: postData.propretorName,
  propretorContact: postData.propretorContact,
  label: postData.label ,
  address: postData.address ,
  address2:postData.address2 ,
  city: postData.city, 
  state: postData.state,
  zip: postData.zip ,
  bankAccName: postData.bankAccName ,
  bankAccNumber: postData.bankAccNumber ,
  branchName: postData.branchName,
  fscCode: postData.fscCode,
  contact_label: postData.contact_label,
  firstName: postData.firstName,
  lastName: postData.lastName,
  email: postData.email,
  workPhone: postData.workPhone,
  mobileNo: postData.mobileNo,
  pay_terms: postData.pay_terms,
  discount: postData.discount
  };

  axios.post( url +"add_supplier", userData).then((response) => {
    console.log(response.status);
  });
  setShow(true);

  //https://blog.logrocket.com/how-to-use-axios-post-requests/
  // axios.post("https://reqres.in/api/login", userData).then((response) => {
  //   console.log(response.status, response.data.token);
  // });
  
}

const addContact_click = () => {
  let saveFlag1 = 0

  for (let i = 0; i < contact.length; i++) {
    let label = document.getElementById('c_label' + i.toString()).value.trim()
    let firstName = document.getElementById('c_firstName' + i.toString()).value.trim()
    let lastName = document.getElementById('c_lastName' + i.toString()).value.trim()
    let email = document.getElementById('c_email' + i.toString()).value.trim()
    let workPhone = document.getElementById('c_workPhone' + i.toString()).value.trim()
    let mobileNo = document.getElementById('c_mobileNo' + i.toString()).value.trim()
    if (label.length == 0 || firstName.length == 0 || lastName.length == 0 || email.length == 0 || workPhone.length == 0 || mobileNo.length ==0 ){
      saveFlag1= 1
    }
  }
  if ( saveFlag1 == 1 ){
    setErrContact('Please Enter all contact fields');
   }
   if ( saveFlag1 == 0 ){
    setErrContact('');
  let newrow =  { id: '',
  label: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNo: '',
  workPhone: ''};
    let newListData = [...contact];
     newListData.push(newrow);
     setContact(newListData);  
   }

}

const add_address_click = () => {
  let saveFlag = 0
  for (let i = 0; i < address.length; i++) {
   let label = document.getElementById('a_label' + i.toString()).value.trim()
   let address = document.getElementById('address' + i.toString()).value.trim()
   let address_2 = document.getElementById('address_2' + i.toString()).value.trim()
   let city = document.getElementById('city' + i.toString()).value.trim()
   let state = document.getElementById('state' + i.toString()).value.trim()
   let zip = document.getElementById('zip' + i.toString()).value.trim()
   if (label.length == 0 || address.length == 0 || city.length == 0 || state.length == 0 || zip.length == 0 ){
    saveFlag = 1
   }
  }
   if ( saveFlag == 1 ){
    setErrAddr('Please Enter all address fields');
   }
   if ( saveFlag == 0 ){
    setErrAddr('');
    let newrow = { id: '',
    label: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: ''};
    let newListData = [...address];
     newListData.push(newrow);
     setAddress(newListData);   

   } 

}


const validate_address_zip = (e) =>{ 

  if ( e.target.value.length > 0){
    setErrAddr('');
  }
  if ( e.target.value.length == 0){
    setErrAddr('Please Enter all address fields');
  }
  
}


const validate_address_state = (e) =>{ 

  if ( e.target.value.length > 0){
    setErrAddr('');
  }
  if ( e.target.value.length == 0){
    setErrAddr('Please Enter all address fields');
  }
  
}
const validate_address_city = (e) =>{ 

  if ( e.target.value.length > 0){
    setErrAddr('');
  }
  if ( e.target.value.length == 0){
    setErrAddr('Please Enter all address fields');
  }
  
}

const validate_address_add2 = (e) =>{ 

  setErrAddr(''); 

  // if ( e.target.value.length > 0){
  //   setErrAddr('');
  // }
  // if ( e.target.value.length == 0){
  //   setErrAddr('Please Enter all address fields');
  // }
  
}

const validate_address_addr1 = (e) =>{ 

  if ( e.target.value.length > 0){
    setErrAddr('');
  }
  if ( e.target.value.length == 0){
    setErrAddr('Please Enter all address fields');
  }
  
}

const validate_address_label = (e) =>{ 

  if ( e.target.value.length > 0){
    setErrAddr('');
  }
  if ( e.target.value.length == 0){
    setErrAddr('Please Enter all address fields');
  }
  
}



const validate_contact_label= (e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}


const validate_contact_fname=(e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}


const validate_contact_lname=(e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}


const validate_contact_email=(e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}

const validate_contact_workPhone=(e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}
const validate_contact_mobile=(e) =>{ 

  if ( e.target.value.length > 0){
    setErrContact('');
  }
  if ( e.target.value.length == 0){
    setErrContact('Please Enter all contact fields');
  }
  
}

useEffect(() => {
  
  const fetchcoursins = async () => {
    // setContact(contactData);
    try {
      const res = await axios.get(
        url +"supplier_id"
        //url +"courses?domain_id=" + domainID
      );
     // setCourses(res.data);
     //setSupplierID
     console.log('masani');
     if ( res.data[0].supplier_id == null){
      setSupplierId('S000'+ 1)
     } else{
      setSupplierId('S000'+ (parseInt(res.data[0].supplier_id , 10) + 1))
     }
  
      console.log(supplierId);
    } catch (error) {
      console.log(error);
    }
  };  
  fetchcoursins();
 
}, []);
    return <>
    <Container fluid>
        <h5>Vendor Master</h5>
    <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Vendor</li>
  </ol>
</nav>
    </Container>

    <div style={{ maxHeight: "calc(100vh - 99px)", overflowY: "auto", }} >
     <Container fluid style={{ background:'white', width:'95%', padding:'15px'}} >
     <Form>
     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Vendor Code
        </Form.Label>
        <Col sm="3">
        <Form.Control
        type="text"
        placeholder= { supplierId }
        aria-label="Disabled input example"
        readOnly
      />
        </Col>
        </Form.Group>
     <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Company Name 
        </Form.Label>
        <Col sm="6">
          <Form.Control type="text" placeholder="Company Name"
           name="companyName"
           value={postData.companyName}
           onChange={handleChange}
            />
        </Col>
        </Form.Group>

        <Row className="mb-3">
        <Form.Group as={Col} controlId="gst_no">
          <Form.Label>GST Number </Form.Label>
          <Form.Control 
          name="gstNumber"
          value={postData.gstNumber}
          onChange={handleChange}
           />
        </Form.Group>

        <Form.Group as={Col} controlId="gst_treatment">
          <Form.Label>GST Treatment </Form.Label>
          <Form.Control 
           name="gstTreatment"
           value={postData.gstTreatment}
           onChange={handleChange}
            />
        </Form.Group>

        <Form.Group as={Col} controlId="pan_no">
          <Form.Label>PAN Number</Form.Label>
          <Form.Control 
          name="panNumber"
          value={postData.panNumber}
          onChange={handleChange}
          />
        </Form.Group>
      </Row>
      
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
        Proprietor Name 
        </Form.Label>
        <Col sm="6">
          <Form.Control type="text" placeholder="Proprietor Name" 
           name="propretorName"
           value={postData.propretorName}
           onChange={handleChange}
          />
        </Col>
        <Col sm="4">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></InputGroup.Text>
        <Form.Control
          placeholder="Proprietor Contact"
          aria-label="Username"
          aria-describedby="basic-addon1"
          name="propretorContact"
           value={postData.propretorContact}
           onChange={handleChange}
        />  
      </InputGroup>
        </Col>
        </Form.Group>
     
        <Tabs
                defaultActiveKey="address"
                id="fill-tab-example"
                className="mb-4"
                fill 
              >
                <Tab eventKey="address" title="Address" className="mb-3" >
                  
                <Table striped bordered hover>
<thead>
        <tr>
          <th>#</th>
          <th>label</th>          
          <th>Address</th>
          <th>Address2</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>          
        </tr>
      </thead>
      <tbody>
       
{address.map((addr, index) => (
   <tr>
      <td>
               {index + 1}
       
        </td> 
        
         
        
        <td>
       
       <Form.Control type="text" placeholder="label" name= {'a_label' + index }
       id =  {'a_label' + index } onChange={validate_address_label}
        />         
      </td>
    
    
            <td>
            <Form.Control type="text" placeholder="Address" name= {'address' + index }
       id =  {'address' + index } onChange={validate_address_addr1}
        />  
          {/* <Form.Control type="test" placeholder="Address" 
            name="address"
            value={postData.address}
            onChange={handleChange}
          /> */}
          </td>
            <td>
            <Form.Control type="text" placeholder="Address 2" name= {'address_2' + index }
       id =  {'address_2' + index } onChange={validate_address_add2}
        />  
         
          </td>
        


            <td>
            <Form.Control type="text" placeholder="City" name= {'city' + index }
       id =  {'city' + index } onChange={validate_address_city}
        />  
         
          </td> 
            <td>
            <Form.Control type="text" placeholder="State" name= {'state' + index }
       id =  {'state' + index } onChange={validate_address_state}
        />  
          
          </td>
        
    
        
    
            <td>
            <Form.Control type="text" placeholder="Zip" name= {'zip' + index }
       id =  {'zip' + index } onChange={validate_address_zip}
       
        />  
          
          
          </td>
        

</tr>

 ))}
 

 </tbody>
</Table>
<Row>
  <Col sm='2'>
  <Button variant="outline-primary" onClick={add_address_click} >Add Contact</Button>
  </Col>
  <Form.Label column sm="10">
      <h5 style={{color:'red'}}> { errAddr } </h5>  
        </Form.Label>
</Row>

   </Tab>
   <Tab eventKey="bank" title="Bank Details"  >
    
   <Form className="mb-3">
   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Bank Account Name
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" placeholder="Bank Account Name"
          name="bankAccName"
          value={postData.bankAccName}
          onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Bank Account Number
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" placeholder="Bank Account Number" 
          name="bankAccNumber"
          value={postData.bankAccNumber}
          onChange={handleChange}
          
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
          Branch Name
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" placeholder="Branch Name"
          name="branchName"
          value={postData.branchName}
          onChange={handleChange}
          
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        FSC Code
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" placeholder="FSC Code"           
          name="fscCode"
          value={postData.fscCode}
          onChange={handleChange}
          
          />
        </Col>
      </Form.Group>

    </Form>

</Tab>
<Tab eventKey="pcontact" title="Primary Contact" className="mb-3">

</Tab>
<Tab eventKey="ocontact" title="Other Contact" className="mb-3">
<Table striped bordered hover>
<thead>
        <tr>
          <th>#</th>
          <th>label</th>          
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Mobile No</th>
          <th> Work Phone</th>          
        </tr>
      </thead>
      <tbody>
       
{contact.map((cont, index) => (
   <tr>
      <td>
       {index + 1}       
        </td> 
        <td>
            <Form.Control type="text" placeholder="label" name= {'c_label' + index }
          id =  {'c_label' + index } onChange={validate_contact_label}
           />         

          {/* <Form.Control type="test" placeholder="label" 
          name="contact_label"
          value={postData.contact_label}
          onChange={handleChange}
          /> */}
          </td>
       
            <td>
            <Form.Control type="text" placeholder="First Name" name= {'c_firstName' + index }
          id =  {'c_firstName' + index } onChange={validate_contact_fname}
           /> 
          
          </td>   

            <td>
            <Form.Control type="text" placeholder="Last Name" name= {'c_lastName' + index }
          id =  {'c_lastName' + index } onChange={validate_contact_lname}
           /> 
          
          </td>
           <td>
            <Form.Control type="text" placeholder="Email" name= {'c_email' + index }
          id =  {'c_email' + index } onChange={validate_contact_email}
           /> 
        
          </td>
      
            <td>
            <Form.Control type="text" placeholder="Work Number" name= {'c_workPhone' + index }
          id =  {'c_workPhone' + index } onChange={validate_contact_workPhone}
           /> 
         
          </td>
        
            <td>
            <Form.Control type="text" placeholder="Mobile Number" name= {'c_mobileNo' + index }
          id =  {'c_mobileNo' + index } onChange={validate_contact_mobile}
           /> 
         
          </td>
        

</tr>

 ))}
 

 </tbody>
</Table>
<Row>
  <Col sm='2'>
  <Button variant="outline-primary"  onClick={addContact_click} >Add Contact</Button>
  </Col>
  <Form.Label column sm="10">
      <h5 style={{color:'red'}}> { errContact } </h5>  
        </Form.Label>
</Row>
</Tab>
<Tab eventKey="odetail" title="Other Details" className="mb-3">
<Form>
   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Payment terms 
        </Form.Label>
        <Col sm="8">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">No. Of days</InputGroup.Text>
        <Form.Control
          placeholder="Payment"
          aria-label="Username"
          aria-describedby="basic-addon1"
          name="pay_terms"
          value={postData.pay_terms}
          onChange={handleChange}
        />
      </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="4">
        Discount on bill
        </Form.Label>
        <Col sm="8">
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Percentage </InputGroup.Text>
        <Form.Control
          placeholder="Discount"
          aria-label="Username"
          aria-describedby="basic-addon1"
          name="discount"
          value={postData.discount}
          onChange={handleChange}
        />  
      </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload Documents</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
      </Form>
</Tab>
</Tabs>

<Row className="mb-5">
            <Col sm={2}>
            <Button variant="outline-primary"  onClick={handleSubmit} >Save</Button>
            </Col>
        </Row>

     </Form>

    

     </Container>
</div>
<div>
<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The supplier Data saved successfully
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
</div>
    </>

}

export default Supplier;